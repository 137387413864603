(function() {
	// 以下是定义rem的基本单位的代码
	var remSwitch = true;
	var oW = null;
	var timer = null;
	reset();
	window.addEventListener("orientationchange", function() {
		reset();
	});
	window.addEventListener("resize", function() {
		reset();
	});

	function reset() {
		if (remSwitch) {
			remSwitch = false;
			oW = document.documentElement.clientWidth;
			if (oW > 1920) {
				// oW = 1920;
				oW = document.documentElement.clientWidth;
			}
			if (1500 > oW && oW >= 1366) {
				oW = document.documentElement.clientWidth * 1.1;
			}
			if (1366 > oW && oW >= 1200) {
				oW = document.documentElement.clientWidth * 1.2;
			}
			if (1200 > oW && oW >= 991) {
				oW = document.documentElement.clientWidth * 1.3;
			}
			if (991 >= oW && oW >= 700) {
				oW = document.documentElement.clientWidth * 2.2;
			}
			if (700>= oW )  {
				oW = 1300;
			}
			document.querySelector("html").style.fontSize = oW / 19.2 + "px";
			timer = setTimeout(function() {
				remSwitch = true;
				clearTimeout(timer);
			}, 200);
		}
	}
})();
