import '../css/animate.css'
// import '../css/swiper-bundle.min.css'
import '../layui/css/layui.css'
import '../css/public.css'
import '../css/style.css'
// import '../utils/jquery-3.3.1.min.js'
import '../utils/rem.js'
import '../utils/jquery.countup.min.js'
// import '../utils/jquery.waypoints.min.js'
// import '../utils/swiper.animate1.0.2.min.js'
// import '../utils/swiper.min.js'
// import '../layui/layui.all.js'
import '../utils/animejs.js'
import { WOW } from '../utils/wow.js';
import Swiper from '../utils/swiper4.min.js'
import '../css/swiper4.min.css'






let $but = document.querySelectorAll('.EditButton');
let $btnlen = $but.length;
if (sessionStorage.getItem('EditButton')) {
	for (let i = 0; i < $btnlen; i++) {
		$but[i].style.display = "none"
	}
}
document.onkeydown = function (event) {
	if (event.keyCode === 119) {
		if (!sessionStorage.getItem("EditButton")) {
			for (let i = 0; i < $btnlen; i++) {
				$but[i].style.display = "none"
			}
			sessionStorage.setItem('EditButton', true)
		} else {
			for (let i = 0; i < $btnlen; i++) {
				$but[i].style.display = "block"
			}
			sessionStorage.removeItem('EditButton')
		}
	}
}


var rem;
if (document.documentElement && document.documentElement.clientHeight && document.documentElement.clientWidth) {
	rem = document.documentElement.clientWidth;
}

// 插入视频弹框
var lpkPhone = window.screen.width;
var layer_str =
	'<div class="layerVideo"><div class="box"><span class="layui-icon layui-icon-close"></span><video src="" controls></video></div></div>'
$('body').append(layer_str);

// 视频点击
function layerVideo(video) {
	let _thisData = $(video).attr("data-video");
	$(".layerVideo video").attr('src', _thisData);
	$(".layerVideo").fadeIn(500);
	$(".layerVideo video").get(0).play();
}
$('.layerVideo .box span').click(function () {
	$(".layerVideo video").attr('src', ' ');
	$('.layerVideo').fadeOut();
	$(".layerVideo video").trigger('pause');
});

// 手机头部点击
$('.MobileNav .box .item>p span').click(function () {
	$(this).toggleClass('active');
	$(this).parents('.item').find('ul').slideToggle();
});

$('.MobileMain .box .button .menu .menu_btn').click(function () {
	$(this).toggleClass('active');
	$('.MobileNav').slideToggle();
});

$('.HeaderMobile .box .button .search').click(function () {
	$('.search_container').fadeIn();
})

$('.search_container .layui-icon-close').click(function () {
	$('.search_container').fadeOut();
})
function isIOS() {
	var u = navigator.userAgent;
	var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
	return isiOS;
}

if (isIOS()) {
	$('.go_top').click(function () {
		$("html,body").animate({
			'scrollTop': 800 + 'px'
		}, 800);
	});
} else {
	$('.go_top').click(function () {
		$("html,body").animate({
			'scrollTop': 0 + 'px'
		}, 0);
	});
}

// 滚动效果插件
new WOW().init();
// 数字滚动
$('.counter').countUp();

// 返回顶部
$('.go_top').on('click', function () {
	$("html,body").animate({
		'scrollTop': 0 + 'px'
	}, 800);
});


// 滚动条事件
var p = 0,
	t = 0;
$(window).scroll(function () {
	var sTop = $(window).scrollTop();
	p = $(this).scrollTop();
	if (sTop > 200) {
		if (t <= p) {
			// 向下
		} else {
			// 向上
		}
		setTimeout(function () {
			t = p;
		}, 0);
	}

	if (sTop > 100) {
		$('.header_container').addClass('active');
	} else {
		$('.header_container').removeClass('active');
	}
});


function Home() {
	new Swiper('.banner_container .banner .swiper-container', {
		loop: true,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},
		// effect: 'fade',
		// direction: 'vertical',
		speed: 800,
		spaceBetween: 0,
		slidesPerView: 1,
		centeredSlides: false,
		navigation: {
			prevEl: '.banner_container .banner .button .prev',
			nextEl: '.banner_container .banner .button .next',
		},
		// watchSlidesProgress: true,
		// virtualTranslate: true, 
		// watchOverflow: true,
		pagination: {
			el: ".banner_container .page div",
			clickable: true,
		},
		breakpoints: {
			320: {
			},
			500: {
			},
			1200: {
			},
			1360: {
			},
		},

		on: {
			init: function () {
				var slideH = this.slides.length / 3;
				// $(this.el)

			},
			slideChangeTransitionEnd: function () {
				var num = this.realIndex;
				num = num + 1;
			},
			// slideChangeTransitionStart: function () {
			// 	var nextSlide = this.slides.eq(this.activeIndex);
			// },
		}
	});

}

// Home();

$('.home_ServiceProvider_container .box .banner .swiper-container .swiper-slide').mouseover(function () {
    $(this).addClass('active').siblings().removeClass('active')
  })

  if (rem > 760) {
    new Swiper('.home_ServiceProvider_container  .banner1  .swiper-container', {
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      watchSlidesProgress: true,
      slidesPerView: "auto",
      centeredSlides: true,
      loop: true,
      loopedSlides: 5,
      autoplay: true,
	  parallax: true,
      pagination: {
        el: ".home_ServiceProvider_container .page div",
        clickable: true,
      },
      navigation: {
        prevEl: '.home_ServiceProvider_container .prev',
        nextEl: '.home_ServiceProvider_container .next',
      },
      on: {
        progress: function (progress) {
          for (var i = 0; i < this.slides.length; i++) {
            var slide = this.slides.eq(i);
            var slideProgress = this.slides[i].progress;
            var modify = 1;
            if (Math.abs(slideProgress) > 1) {
              modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
            }
           var translate = slideProgress * modify * 105 + 'px';
           var scale = 1 - Math.abs(slideProgress) / 5;
           var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
            slide.transform('translateX(' + translate + ') scale(' + scale + ')');
            slide.css('zIndex', zIndex);
            slide.css('opacity', 1);
            if (Math.abs(slideProgress) > 3) {
              slide.css('opacity', 0);
            }
          }
        },
        setTransition: function (transition) {
          for (var i = 0; i < this.slides.length; i++) {
            var slide = this.slides.eq(i)
            slide.transition(transition);
          }
        }
      }

    })
  } else {
    new Swiper('.home_ServiceProvider_container  .banner1  .swiper-container', {
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      slidesPerView: 1,
      loop: true,
      loopedSlides: 5,
      autoplay: true,
      pagination: {
        el: ".home_ServiceProvider_container .page div",
        clickable: true,
      },
      navigation: {
        prevEl: '.home_ServiceProvider_container .prev',
        nextEl: '.home_ServiceProvider_container .next',
      },
    })
  }